import React from 'react';

const Promo2PR = () => {
  return (
    <section id="promo2">
      <div className="promo2-inner">
        <p className="body-text4">
          Запрошуємо до участі в <strong>наших курсах</strong>, які включають оплачуване стажування та можливість <strong>працевлаштування</strong> після успішного завершення навчання.
        </p>
        <a
          target="blank"
          href="https://www.canva.com/design/DAGDRKX1x6Q/rS0hGa82RmhJZ6Ze1WMBjg/view?utm_content=DAGDRKX1x6Q&utm_campaign=designshare&utm_medium=link&utm_source=editor"
          className="text-dec-n"
        >
          <button className="pr-btn">СКIЛЬКИ Я ЗМОЖУ ЗАРОБИТИ</button>
        </a>
      </div>
    </section>
  );
};

export default Promo2PR;
