import React from 'react';
import Intro from './sections/Intro';
import Promo1 from './sections/Promo1';
import Publications from './sections/Publications';
import Header from './Header';
import BenefitsBS from './sections/Benefits-bs';
import BusinessBS from './sections/Business-bs';
import Promo2BS from './sections/Promo2-bs';
import Promo3BS from './sections/Promo3-bs';

const MainForBusiness = () => {
  return (
    <div id="main">
      <Header />
      <Intro />
      <Promo1 />
      <BusinessBS />
      <Promo2BS />
      <BenefitsBS />
      <Promo3BS />
      <Publications />
    </div>
  );
};

export default MainForBusiness;
