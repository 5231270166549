import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import business from '../../assets/img/svg/business.svg';

const BusinessPR = () => {
    return (
    <section id="business" className="bt bb sec">
      <div className="business-image br">
        <LazyLoadImage src={business} width={408} height={326} />
      </div>
      <div className="pl-6 sec">
        <div className="flex aic mb-8">
          <div className="item"></div>
          <h3 className="h3">Лідогенераторам</h3>
        </div>
        <p className="body-text2">ESUPPORT пропонує інноваційну платформу для спеціалістів у сфері лідогенерації, які бажають поглибити свої навички в B2B-сегменті та використовувати ефективні інструменти LinkedIn для досягнення професійних успіхів. Наші ресурси і тренінги зосереджені на практичному застосуванні стратегій і методик, які допоможуть вам ефективно залучати потенційних клієнтів та оптимізувати ваші продажі.</p>
      </div>
    </section>
  );
};

export default BusinessPR;
