import React from 'react';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import logo from '../logo_48x48.svg'

const Footer = () => {
  const year = new Date()
  return (
    <div id="footer" className="flex jcsb bt">
      <div className="flex fdc jcsb">
        <div className="fgrow pb-4">
          <img src={logo} alt="ESUPPORT" width={48} height={48} />
        </div>
        <div className="flex aic">
          <AiOutlineCopyrightCircle />
          <p className="body-text7 tup ml-2">ESUPPORT - {year.getFullYear()}</p>
        </div>
      </div>
      <div className="flex fdc jcsb">
        <div>
          <p className="body-text6 tup">ГО ”АФК”</p>
          <p className="body-text6 tup">ЄДРПОУ 44654573</p>
        </div>
        <p className="body-text7">Усi Права Захищено.</p>
      </div>
    </div>
  );
};

export default Footer;
