import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import './styles/custom.sass';
import MainForBusiness from './components/MainForBusiness';
import MainForPR from './components/MainForPR';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<MainForBusiness/>}/>
            <Route path='we-are-hiring' element={<MainForPR/>}/>
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
