import React from 'react';
import IntroPR from './sections/IntroPR';
import Promo1 from './sections/Promo1';
import Publications from './sections/Publications';
import HeaderH from './HeaderH';
import BenefitsPR from './sections/Benefits-pr';
import BusinessPR from './sections/Business-pr';
import Promo2PR from './sections/Promo2-pr';
import Promo3PR from './sections/Promo3-pr';

const MainForPR = () => {
  return (
    <div id="main">
      <HeaderH />
      <IntroPR />
      <Promo1 />
      <BusinessPR />
      <Promo2PR />
      <BenefitsPR />
      <Promo3PR />
      <Publications />
    </div>
  );
};

export default MainForPR;
