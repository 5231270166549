import React from 'react';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

const IconButtonPrevious = ({onClick}) => {

  return (
    <button className="icon-btn" onClick={()=>onClick()}><MdOutlineArrowBackIosNew/></button>
  )
}

export default IconButtonPrevious