import React from 'react'

const Promo1 = () => {
  return (
    <section id="promo1">
        <h2 className="h2 textr">ПІДНІМІТЬ ЕФЕКТИВНІСТЬ</h2>
        <h2 className="h2 pl-25 mb-4">ВАШОЇ B2B ЛІДОГЕНЕРАЦІЇ!</h2>
        <p className="body-text1">професійні стратегії комунікацій у мережі</p>
        <h3 className="h3">LinkedIn</h3>
      </section>
  )
}

export default Promo1
