import React from 'react';
import { BsTelegram } from 'react-icons/bs';

const Promo3PR = () => {

  return (
    <section id="promo3" className="bb">
      <div className="promo3-inner">
        <div className="flex aic">
          <div className="item"></div>
          <h4 className="h4">Розвиток професійних навичок</h4>
        </div>
        <p className="body-text4">Отримайте цінний досвід та розширте свої навички в лідогенерації, працюючи з командою професіоналів в ESUPPORT!</p>
        <div>
          <a
            className="text-dec-n"
            target="blank"
            href="https://t.me/+IajyUr7r9zcwZjli"
          >
            <button className="pr-btn">
              наш чат <BsTelegram />
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Promo3PR;
