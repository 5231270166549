import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { BsFillTelephoneFill } from 'react-icons/bs';
import intro from '../../assets/img/svg/intro.svg';
import { selectNavigationLink } from '../../redux/slices/navigationSlice';

const Intro = () => {
  const navValue = useSelector(selectNavigationLink);
  return (
    <section id="intro" className="bb">
      <div className="intro-text">
        <h1 className="h1">ESUPPORT</h1>
        <p className="body-text6">
          Компанія ESUPPORT спеціалізується на B2B лідогенерації через LinkedIn, надаючи послуги, що дозволяють вашому бізнесу налагодити та зміцнити делові зв'язки з потенційними клієнтами та партнерами. Наші послуги допоможуть вашому бізнесу встановити та зміцнити делові зв'язки з потенційними клієнтами та партнерами,
          використовуючи потужні інструменти комунікації та маркетингу для досягнення ваших стратегічних бізнес-цілей.
        </p>
        <div className="buttons flex jcsb">
          <a
            className="text-dec-n"
            target="blank"
            href="https://form.typeform.com/to/GMST2l9O#book_call=true&start_now=xxxxx&free_audit=xxxxx&get_icp=xxxxx"
          >
            <button className="sec-btn">
              ЗАМОВИТИ ДЗВIНОК
              <BsFillTelephoneFill />
            </button>
          </a>
          <a
            target="blank"
            href={
              navValue === 'business'
                ? 'https://form.typeform.com/to/GMST2l9O#book_call=xxxxx&start_now=true&free_audit=xxxxx&get_icp=xxxxx'
                : 'https://form.typeform.com/to/GMST2l9O#book_call=xxxxx&start_now=true&free_audit=xxxxx&get_icp=xxxxx'
            }
            className="text-dec-n"
          >
            <button className="pr-btn">ПОЧАТИ ЗАРАЗ</button>
          </a>
        </div>
      </div>
      <div className="intro-image">
        <LazyLoadImage src={intro} width={473.5} />
      </div>
    </section>
  );
};

export default Intro;
