import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import benefits from '../../assets/img/svg/benefits.svg';

const BenefitsPR = () => {
  return (
    <section id="benefits" className="bb bt">
        <div className="benefits-image">
          <h3 className="h3">наші переваги</h3>
          <LazyLoadImage src={benefits} height={614} width={510} />
        </div>
        <div className="benefits-text">
          <div className="benefits-inner">
            <div className="block bb">
              <h3 className="h3">Стратегії лідогенерації</h3>
              <p className="body-text5">
              Ми надаємо знання та практичні навички для розробки та впровадження ефективних стратегій лідогенерації в LinkedIn.
              </p>
            </div>
            <div className="block bb">
              <h3 className="h3">Індивідуальний підхід та підтримка</h3>
              <p className="body-text5">
              Отримайте індивідуальний підхід та підтримку в професійному розвитку від нашої команди лідерів галузі, які зацікавлені у вашому успіху.
              </p>
            </div>
            <div className="block bb">
              <h3 className="h3">Використання автоматизованих інструментів для ефективного ведення кампаній лідогенерації</h3>
              <p className="body-text5">
              Вдосконаліть свої навички в автоматизації PR-кампаній, використовуючи передові технологічні рішення, та оптимізуйте процеси комунікації з клієнтами.
              </p>
            </div>
            <div className="block">
              <h3 className="h3">Мережеві можливості</h3>
              <p className="body-text5">
              Розширте свій професійний кругозір, встановлюючи зв'язки з провідними фахівцями галузі та отримуючи доступ до ексклюзивних мережевих заходів в сфері PR через нашу спільноту в LinkedIn.
              </p>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BenefitsPR
