import React from 'react';

const Promo2BS = () => {
  return (
    <section id="promo2">
      <div className="promo2-inner">
      <p className="body-text4">
        ОТРИМАЙТЕ <strong>БЕЗКОШТОВНИЙ АУДИТ</strong> ВАШОГО БІЗНЕСУ ВІД ЕКСПЕРТІВ <strong>ESUPPORT</strong> ТА ВІДКРИЙТЕ НОВІ ГОРИЗОНТИ ДЛЯ МАСШТАБУВАННЯ ВАШОГО БІЗНЕСУ.
      </p>
        <a
          target='blank'
          href='https://form.typeform.com/to/GMST2l9O#book_call=xxxxx&start_now=xxxxx&free_audit=true&get_icp=xxxxx'
          className="text-dec-n">
            <button className="pr-btn">ОТРИМАТИ БЕЗКОШТОВНИЙ АУДИТ</button>
        </a>
      </div>
    </section>
  );
};

export default Promo2BS;
