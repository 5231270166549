import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeNavigationLink,
  selectNavigationLink,
} from '../redux/slices/navigationSlice';
import { BsTelegram } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import logo from '../logo_48x48.svg'

const HeaderH = () => {
  const dispatch = useDispatch();
  const navValue = useSelector(selectNavigationLink);

  const changeNavLink = (value) => {
    dispatch(changeNavigationLink(value));
  };

  return (
    <div id="header" className="flex jcsb">
      <div className="flex aic" style={{padding: '8px 0'}}>
        <img src={logo} alt="ESUPPORT" width={64} height={64} /><p style={{fontFamily: 'Inter', fontSize: '1.25em', fontWeight: 800, paddingLeft: 16}}>ESUPPORT</p>
      </div>
      <div className="flex aife jcsb w-500 bl pl-6 pb-4">
        <Link to="/">
          <button
            type="button"
            className={navValue === 'business' ? 'menu-btn active' : 'menu-btn'}
            onClick={() => changeNavLink('business')}
          >
            бізнесу
          </button>
        </Link>
        <Link to="/we-are-hiring">
          <button
            type="button"
            className={navValue === 'piar' ? 'menu-btn active' : 'menu-btn'}
            onClick={() => changeNavLink('piar')}
          >
            Ми наймаємо
          </button>
        </Link>
        <a
          className="text-dec-n"
          target="blank"
          href="https://t.me/+IajyUr7r9zcwZjli"
        >
          <button className="menu-chat-btn">
            <BsTelegram />
          </button>
        </a>
      </div>
    </div>
  );
};

export default HeaderH;
