import { createSlice } from "@reduxjs/toolkit";

const initialState = "business";

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    changeNavigationLink: (state, action) => {
      return action.payload;
    },
  },
});

export const { changeNavigationLink } = navigationSlice.actions;

export const selectNavigationLink = (state) => state.navigation;

export default navigationSlice.reducer;
