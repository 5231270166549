import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import business from '../../assets/img/svg/business.svg';

const BusinessBS = () => {
  return (
    <section id="business" className="bt bb">
      <div className="business-image br">
        <LazyLoadImage src={business} width={408} height={326} />
      </div>
      <div className="pl-6">
        <div className="flex aic mb-8">
          <div className="item"></div>
          <h3 className="h3">бізнесу</h3>
        </div>
        <p className="body-text2">Розширте свої комерційні горизонти та встановіть міцні партнерські відносини з ключовими гравцями вашої галузі за допомогою наших послуг лідогенерації в мережі LinkedIn.</p>
      </div>
    </section>
  );
};

export default BusinessBS;
