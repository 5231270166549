import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import benefits from '../../assets/img/svg/benefits.svg';

const BenefitsBS = () => {
  return (
    <section id="benefits" className="bb bt">
        <div className="benefits-image">
          <h3 className="h3">наші переваги</h3>
          <LazyLoadImage src={benefits} height={614} width={510} />
        </div>
        <div className="benefits-text">
          <div className="benefits-inner">
            <div className="block bb">
              <h3 className="h3">ефективні стратегії PR</h3>
              <p className="body-text5">
              Розробка та впровадження стратегій для ефективної лідогенерації та підвищення видимості вашого бізнесу в LinkedIn.
              </p>
            </div>
            <div className="block bb">
              <h3 className="h3">Персоналізований підхід</h3>
              <p className="body-text5">
              Персоналізований аналіз та оптимізація вашого бізнес-профілю на LinkedIn для залучення більшої кількості потенційних клієнтів.
              </p>
            </div>
            <div className="block bb">
              <h3 className="h3">Автоматизоване управління кампаніями</h3>
              <p className="body-text5">
              Використання сучасних інструментів для автоматизації лідогенераційних кампаній.
              </p>
            </div>
            <div className="block">
              <h3 className="h3">Мережеві можливості</h3>
              <p className="body-text5">
              Доступ до широкої мережі потенційних клієнтів та партнерів у сфері B2B з командой ESUPPORT.
              </p>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BenefitsBS
